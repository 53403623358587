var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.is_loaded
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Back"),
              ]),
              _c("span", { staticClass: "ref" }, [
                _vm._v(" / "),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.estimate_id) +
                      " / Payment plan / " +
                      _vm._s(_vm.payment_plan.ref) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("span", { staticClass: "contact-email-header" }, [
            _vm._v(" Contact emails for pending invoices: "),
          ]),
          _c("paymentplan-emails", {
            attrs: { payment_plan: _vm.payment_plan },
            on: { change: _vm.handleUpdateContactEmails },
          }),
          _c(
            "div",
            { staticClass: "paymentplan-form-footer" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Cancel"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.is_disabled },
                  on: { click: _vm.handleSave },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }